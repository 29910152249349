.CodeMirror {
  height: 25px;
}
.ReactCodeMirror {
  height: 30px;
}
.cm-category {
  color: #2196F3;
}
.cm-operator {
  color: #9E9E9E;
}
.cm-value {
  color: #E91E63;
}
.react-filter-box {
  overflow-y: hidden;
  height: 28px;
  padding: 4px 6px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
  color: #555555;
  vertical-align: middle;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border linear 0.2s, box-shadow linear 0.2s;
  -moz-transition: border linear 0.2s, box-shadow linear 0.2s;
  -o-transition: border linear 0.2s, box-shadow linear 0.2s;
  transition: border linear 0.2s, box-shadow linear 0.2s;
}
.react-filter-box.focus {
  border-color: #66afe9;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.react-filter-box.error {
  border-color: #a94442;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.react-filter-box.error.focus {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
}
.CodeMirror-hints {
  padding: 5px;
  min-width: 100px;
}
.CodeMirror-hints .CodeMirror-hint {
  padding: 5px 5px;
}
li.CodeMirror-hint-active {
  background: #2196F3;
}
li.CodeMirror-hint-active .hint-value {
  color: white;
}
